@import "section";
@import "header";
@import "form";
@import "card";
@import "persone";
@import "button";
@import "pureFoot";

html{
  scroll-behavior: smooth;
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  outline: none !important;
  box-shadow: none;
}

*:focus,
*:hover,
*:active{
  outline: none !important;
  box-shadow: none;
}

.m-mobile{
  @include media-breakpoint-down(md){
      margin-bottom: 3rem;
  }
}
