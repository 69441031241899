.btn{

  &-outline-secondary{

    &:hover,
    &:focus,
    &:active{
      color: $primary;
      background: $secondary;
    }
  }
}