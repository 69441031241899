#header{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  transition: $transition-base;
}


.subheader{
  &__container{
    background-color: $primary;
    position: relative;

    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('/img/header-image.png');
      background-repeat: no-repeat;
      background-size: cover;
      mix-blend-mode: multiply;

      @include media-breakpoint-up(md){
        width: 60%;
        height: 90%;
      }
    }
  }

  &__row{
    min-height: 85vh;
  }

  &__top-row{
    padding-top: 40px;
    padding-bottom: 20px;
    position: relative;

    &:before{
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: $grid-gutter-width / 2;
      right: $grid-gutter-width / 2;
      height: 1px;
      background-color: #fff;
    }
  }

  &__address{
    font-size: 13px;
  }

  &__title{}
}