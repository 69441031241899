.form{
  &__container{
    @extend .subheader__container;

    &:before{
      left: auto;
      top: auto;
      bottom: 0;
      right: 0;
      background-image: url('/img/form-header.png');
      background-attachment: fixed;

      @include media-breakpoint-up(md){
          height: 65%;
          background-position: left top;
      }
    }
  }

  &-control{
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }
  &-group{
    margin-bottom: 2rem;
  }
}

.input-group-text{
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding-left: 0;
  padding-right: 0;
}

#respond{
  & svg{
    width: 25px;
    height: 25px;
  }
}

.custom-control-label{
  font-size: 13px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}