.card{
  margin-bottom: $grid-gutter-width;
  
  &-nomargin{
    margin-bottom: $grid-gutter-width;
    @include media-breakpoint-up(md){
      margin-bottom: 0;
    }
  }

  &-title{
    font-weight: 700;
    margin-bottom: 4.75rem;
  }
}